
import Vue from 'vue'
import UsuarioEvento from '../../components/cadastros/UsuarioEvento.vue'

export default Vue.extend({
  name: 'Usuarioevento',
  components: {
    UsuarioEvento
  }
})
